.text-stroke {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

.text-stroke-none {
    -webkit-text-stroke-width: 0px;
    -webkit-text-stroke-color: white;
}

.text-stroke-thin {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}