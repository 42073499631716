.toast-progress-svg {
    stroke-dashoffset: 0;
    stroke-width: 15%;
    fill: none
}

.toast-progress-bg {
    stroke: '#000000';
    opacity: 0.3;
}

.toast-progress-indicator {
    stroke: '#000000'
}