.logo1 {
    background-color: pink;
    // mask: url(svg/thg_logo.svg);
}

.logo2 {
    background-color: pink;
    mask: url(svg/thg_logo_masked.svg);
    -webkit-mask: url(svg/thg_logo_masked.svg);
}

.logo3 {
    background-color: pink;
    mask: url(svg/thg_logo_masked_2.svg);
    -webkit-mask: url(svg/thg_logo_masked_2.svg)
}

.logo4 {
    background-color: pink;
    mask: url(svg/thg_logo_only\ 3\ \(Masked\).svg);
    -webkit-mask: url(svg/thg_logo_only\ 3\ \(Masked\).svg)
}